import { RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";
import bcfRoutes from '../components/brunei-content-festival/routes';

const routes: RouteRecordRaw[] = [
    ...bcfRoutes,
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router;