<template></template>

<script setup lang="ts">
import { type GoogleRecaptchaSettings } from '@/modules/core/types';
import { useBcfCommonDataStore } from '../store';


interface Props {
    googleRecaptchaSettings?: GoogleRecaptchaSettings;
}

const props = defineProps<Props>();

useBcfCommonDataStore().$patch(props)
</script>