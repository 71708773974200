<script lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router';
import { LayoutResolver, CommonDataProvider } from './components';
import { ToastContainer } from '@/modules/core/components';

export default defineComponent({
    components: {
        RouterView,
        LayoutResolver,
        ToastContainer,
        CommonDataProvider
    },
})
</script>
