import { App } from 'vue';
import { ModuleNamespace } from 'vite/types/hot';

export function registerLayouts(app: App<Element>) {
    const layouts = import.meta.glob<true, string, ModuleNamespace>('./*.vue', { eager: true });
    const blacklist = ['layout-resolver'];

    for (const [path, module] of Object.entries(layouts)) {
        if (!path.includes('.vue')) continue;
        if (blacklist.some(x => path.includes(x))) continue;

        const pathParts = path.split('/')
        const componentName = pathParts[pathParts.length - 1].replace('.vue', '');
        app.component(componentName, module.default);
    }
}

export { default as LayoutResolver } from './layout-resolver.vue';
