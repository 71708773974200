import "./scss/core.scss";
import "./scss/index.scss";
import 'virtual:svg-icons-register';

import { createApp } from "vue";
import App from './brunei-content-festival.vue';
import router from './router/routes';
import { registerLayouts } from "./components";
import pinia from './store';
import * as Sentry from "@sentry/vue";

const app = createApp(App);

if (import.meta.env.MODE !== 'development') {
    Sentry.init({
        app,
        dsn: "https://7f676ae6fbc145e9b1fbc2a7701268d1@o4504790555623424.ingest.sentry.io/4505010639601664",
        environment: import.meta.env.MODE,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "broadcast.bitlab.services", /^\//],
          }),
          new Sentry.Replay({
            networkDetailAllowUrls: [window.location.origin]
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
    });
}


app.use(router);
app.use(pinia);

registerLayouts(app);

app.mount('#app');
