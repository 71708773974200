import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'bcf-landing',
        component: () => import('./brunei-content-festival-page.vue'),
        meta: { layout: 'blank-layout' },
        children: [
            {
                name: 'bcf-upload',
                path: 'upload',
                component: () => import('./brunei-content-festival-video-upload-modal.vue'),
                props: route => ({
                    eager: true,
                    videoUploadUrl: route.query.videoUploadUrl,
                }),
            },
            {
                name: 'bcf-tnc',
                path: 'tnc',
                component: () => import('./brune-content-festival-tnc-modal.vue'),
                props: { eager: true }
            }
        ]
    }
]

export default routes;